.clickable {
  &:hover {
    color: var(--primary-color);
    cursor: pointer;

    svg {
      color: var(--primary-color);
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  &Container {
    @extend .flex;
    justify-content: center;
    overflow-y: hidden;
  }

  &Title {
    @extend .flex;
    justify-content: space-between;
  }
}
