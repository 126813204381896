.card {
  position: relative;
  overflow: visible;
  min-height: 288px;
}

.title {
  text-transform: capitalize;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  margin-left: 55px;
}
.cardContent {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;
  // max-height: 75vh;
  min-height: 288px;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.icon {
  margin-bottom: 10px;
  &Left {
    margin-left: auto;
    background-color: #ab47bc;
    &:hover {
      background-color: #8e24aa;
    }
    &Issues {
      background-color: #ef5350;
      &:hover {
        background-color: #e53935;
      }
    }
  }
  &Overdue {
    margin-right: 5px;
  }
}

.table {
  scrollbar-width: thin;
  scrollbar-color: #fff;
  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  thead tr th {
    white-space: nowrap;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.firstCell {
  padding-left: 50px;
}

.secondCell {
  min-width: 500px;
  // max-width: 500px;
}

.departmentName {
  font-style: oblique;
  opacity: 0.65;
}

.lastCell {
  // Moves the cell all the way to the right of the table
  width: 1px;
}

.label {
  opacity: 0.65;
}

.delete {
  color: #ef5350;
}

.complete {
  opacity: 1;
  color: #66bb6a;
}

.incomplete {
  opacity: 1;
  color: #ef5350;
}

.flex {
  display: flex;
  align-items: center;
  &Center {
    @extend .flex;
    justify-content: center;
  }
  &Relative {
    @extend .flex;
    justify-content: center;
    position: relative;
  }
}

.modelButtons {
  text-align: center;
}

.completed {
  text-decoration: line-through;
}

.marginBottom {
  margin-bottom: 8px;
}

.skeletonRow {
  height: 50px;
}

.menuButton {
  margin: 4px;
  &Todos {
    margin: 4px;
    background-color: #ab47bc;
    &:hover {
      background-color: #8e24aa;
    }
  }
  &Issues {
    margin: 4px;
    background-color: #ef5350;
    &:hover {
      background-color: #e53935;
    }
  }
}

.searchField {
  margin: 4px;
}

.tieInItem {
  max-width: 500px;
  display: flex;
  align-items: center;
}
.tieInItemText {
  max-width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
