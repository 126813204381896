.label {
  opacity: 0.65;
  font-weight: 400;
  // @media screen and (max-width: 600px) {
  //   display: none;
  // }
}

.button {
  display: flex;
  align-items: center;
  text-transform: none;
}
