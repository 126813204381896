.scrollContainer {
  overflow-x: auto;
  height: 100%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 2px 0px;
  display: flex;
  flex: 1;
}

.container {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 350px;
  position: relative;
}

.toolbar {
  border-top: 1px solid #e0e0e0;
  padding: 5px 8px;
}

.toolbarLabel {
  font-weight: bold;
  font-size: 20px;
  margin: 0px 8px;
}

.header {
  display: flex;
  border-top: 1px solid #e0e0e0;

  & div {
    position: relative;
    flex: 1 1 0%;
    min-width: 56px;
    border-right: 1px solid #e0e0e0;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 8px;
    font-weight: bold;
  }
}

.datesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
  flex: 1;
}

.date {
  position: relative;
  min-width: 56px;
  flex: 1 1 14.2857%;
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  height: auto;
}

.todayDate {
  @extend .date;
  background-color: rgb(235, 246, 233);
}

.dateLabel {
  padding-left: 8px;
  margin-top: 5px;
}

.meetingLabel {
  background-color: rgb(236, 204, 239);
  padding-left: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-bottom: 1px;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: rgb(223, 153, 229);
  }
}
