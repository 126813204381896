.tableContainer {
  margin: 4px 0;
}

.tableHead {
  background-color: var(--primary-color);
  border: 1px solid white;

  th {
    color: white;
  }
}

.tableBody {
  > tr {
    background-color: #fd9ba2;
  }

  > tr:nth-child(even) {
    background-color: #fecdd0;
  }

  td {
    padding: 6px;
    border: 1px solid white;

    .tdOuter {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 6px;

      .tdMid {
        position: relative;

        .overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          background-color: var(--primary-color);
          border: 1px solid white;
          padding: 6px;
          color: white;
        }

        .overlay,
        .tdInner {
          transition: 0.3s all;
        }

        &:hover {
          .overlay {
            opacity: 1;
          }
          // .tdInner {
          //   -webkit-filter: blur(2px);
          //   filter: blur(2px);
          // }
        }
      }

      .tdInner {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;

        .plans {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
        }

        .content {
          display: flex;
          text-align: left;
          flex-direction: column;

          &Solo {
            @extend .content;
            width: 100%;
            text-align: center;
            margin: 0;
          }
        }
      }
    }
  }
}

.input {
  display: none;
}

.filename {
  margin-left: 5px;
}

.listItemText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.avatar {
  background-color: var(--primary-color);
  margin-right: 10px;
}

.downloadLink {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  // margin-left: 20px;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.image {
  max-width: 100%;
  margin-top: 6px;
}

.deleteConfirmHover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
