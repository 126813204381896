.inactive {
  opacity: 0.65;
  filter: grayscale(1);
  transition: all 0.3s;
}

.item {
  display: flex;
  align-items: center;
}

.active {
  opacity: 1;
  filter: grayscale(0);
  transition: all 0.3s;
}

.avatar {
  height: 25px;
  width: 25px;
  text-transform: uppercase;
  background-color: var(--primary-color);
  font-size: 12px;
}
