.mainBtn {
  margin-top: 8px;
  margin-bottom: 8px;
  display: block;
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}
