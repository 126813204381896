.button {
  min-width: 81px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.llmButton {
  padding-top: 0;
  padding-bottom: 0;
}
