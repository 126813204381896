.board {
  height: 100%;
  display: flex;
}

.column {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 350px;
  overflow-x: hidden;

  &Header {
    display: flex;
    align-items: center;
    border-radius: 8px;

    &:hover {
      cursor: move;
    }

    &Collapsed {
      @extend .columnHeader;
      writing-mode: vertical-rl;
      cursor: auto;
      height: 100%;

      &:hover {
        cursor: auto;
      }
    }

    &Btn {
      padding: 4px;
    }
  }

  &Cards {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.card {
  border-radius: 8px;
  min-height: 85px;
  border: 2px solid;

  &Header {
    border-radius: 6px 6px 0px 0px;
    padding: 4px 4px;
    border-bottom: 2px solid;
    display: flex;
    align-items: center;
  }

  &Content {
    display: flex;
    flex-direction: column;
  }
}

.dragHandle {
  margin-right: 18px;
  transform: translateX(50%);

  &:hover {
    cursor: move;
  }
}
