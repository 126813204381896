.flex {
  display: flex;
  gap: 10px;
  width: 100%;
}

.contentWrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.cardContainer {
  position: relative;
  margin: 10px 0;
  width: 100%;

  &Inactive {
    @extend .cardContainer;
    opacity: 0.65;
  }
}

.link {
  text-decoration: none;
}

.card {
  cursor: pointer;
}

.buttons {
  z-index: 2;
  position: absolute;
  bottom: 12.5px;
  right: 12.5px;
}

.fiscalYear {
  max-width: 55%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media screen and (max-width: 960px) {
    span {
      display: none;
    }
  }
}

.auth {
  text-transform: capitalize;
}

.logoutBtn {
  color: #ef5350;
  padding-bottom: 16px !important;
}

.createBtn {
  margin-top: 16px;
  margin-bottom: 16px;
}

.successText {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bold;
}
