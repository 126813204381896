.avatar {
  text-transform: uppercase;
  background-color: var(--primary-color);
  margin-right: 5px;
  font-size: 14px;
  &None {
    @extend .avatar;
    background-color: #ef5350;
  }
}

.center {
  display: inline-flex;
  align-items: center;
}
