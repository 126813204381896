.container {
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 16px;

  .inputLabel {
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
    position: absolute;
    top: -10px;
    color: rgba(0, 0, 0, 0.54);
    background-color: white;
  }
}
