.button {
  min-width: 133px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.label {
  opacity: 0.65;
}

.llmButton {
  padding-top: 0;
  padding-bottom: 0;
}

.listIcon {
  min-width: 35px;
}

.scListItem {
  padding-left: 6px;
}

.resetBtn {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
