.version {
  margin-top: 20px;
}

.linkContainer {
  display: flex;
  justify-content: center;
}

.link {
  margin: 0 4px;
  font-size: 0.6rem;
}
