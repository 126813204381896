.card {
  position: relative;
  overflow: visible;
  min-height: 288px;
}

.title {
  text-transform: capitalize;
}

.label {
  opacity: 0.65;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  margin-left: 55px;
}

.cardContent {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;
  // max-height: 75vh;
  padding-top: 0;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.icon {
  margin-bottom: 10px;
  &Left {
    margin-left: auto;
    background-color: #9ccc65;
    &:hover {
      background-color: #7cb342;
    }
  }
  &Accuracy {
    margin-right: 5px;
  }
}

.table {
  position: relative;
  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  thead tr th {
    white-space: nowrap;
  }
}

.firstCell {
  padding-left: 60px;
  padding-right: 0;
  width: 85px;
  min-width: 85px;
  max-width: 85px;
}

.departmentName {
  font-style: oblique;
  opacity: 0.65;
}

.lastCell {
  min-width: 400px;
}

.flex {
  display: flex;
  align-items: center;
  &Center {
    @extend .flex;
    justify-content: center;
  }
}

.metricInput {
  display: flex;
}

.unit {
  margin-left: 10px;
  max-width: 150px;
}

.delete {
  color: #ef5350;
}

.week {
  padding: 0 30px;
  cursor: pointer;

  &Past {
    @extend .week;
    background-color: rgba(0, 0, 0, 0.065);
    opacity: 0.65;
    font-style: oblique;
  }
  &Current {
    @extend .week;
    background-color: rgba(126, 87, 194, 0.2);
  }
}

.cellPast {
  background-color: rgba(0, 0, 0, 0.065);
  opacity: 0.65;
  font-style: oblique;
}

.cellCurrent {
  background-color: rgba(126, 87, 194, 0.2);
}

.cellSelected {
  background-color: rgba(126, 87, 194, 0.2);
}

.tooltipStats {
  padding: 4px 16px;
  background-color: #424242;
  border-radius: 4px;
  font-weight: bold;
  &Red {
    @extend .tooltipStats;
    background-color: #f44336;
  }
  &Green {
    @extend .tooltipStats;
    background-color: #4caf50;
  }
}

.value {
  width: 100%;
  input {
    text-align: center;
  }
  div {
    width: 75%;
    max-width: 160px;
    margin: 0 auto;
    border-radius: 4px;

    // &::before {
    //   display: none;
    // }
    // &::after {
    //   display: none;
    // }
  }
  &Above {
    @extend .value;
    input {
      background-color: rgba(102, 187, 106, 0.3);
    }
  }
  &Below {
    @extend .value;
    input {
      background-color: rgba(239, 83, 80, 0.3);
    }
  }

  &Text {
    font-weight: 600;
  }
}

.modelButtons {
  text-align: center;
}

.metricToggle {
  position: relative;
  label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  input {
    // color: transparent;
    opacity: 1;
    border-radius: 4px;
    &:focus {
      opacity: 1;
      color: #000;
    }
    &:disabled {
      opacity: 1;
      color: #000;
    }
  }
}

.skeletonRow {
  height: 50px;
}

.row {
  height: 45px;
}

.name {
  flex-grow: 1;
  // width: 250px;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.fixed {
  @media screen and (min-width: 1280px) {
    position: sticky;
    height: 45px;
    background: #fff;
    z-index: 2;
    div {
      display: flex;
      align-items: center;
      // height: 100%;
    }
    &:first-child {
      min-width: 85px;
      max-width: 85px;
      left: -20px;
      padding-left: 60px;
      padding-right: 0;
    }
    &:nth-child(2) {
      min-width: 120px;
      max-width: 120px;
      left: 65px;
    }
    &:nth-child(3) {
      min-width: 500px;
      max-width: 500px;
      left: 185px;
    }
    &:nth-child(4) {
      width: 125px;
      left: 685px;
    }
  }
}

.fixedHeader {
  th {
    text-align: center;
    position: sticky;
    height: 45px;
    top: 0;
    z-index: 2;
    background-color: #fff;
    &:first-child {
      width: 85px;
      min-width: 85px;
      max-width: 85px;
    }
  }
  @media screen and (min-width: 1280px) {
    th {
      div {
        display: flex;
        align-items: center;
        // height: 100%;
      }
      &:first-child {
        padding-left: 60px;
        padding-right: 0;
        left: -20px;
        z-index: 3;
      }
      &:nth-child(2) {
        width: 120px;
        max-width: 120px;
        min-width: 120px;
        left: 65px;
        z-index: 3;
      }
      &:nth-child(3) {
        width: 500px;
        min-width: 500px;
        max-width: 500px;
        left: 185px;
        z-index: 3;
      }
      &:nth-child(4) {
        min-width: 125px;
        max-width: 125px;
        left: 685px;
        z-index: 3;
      }
    }
  }
}

.saveButton {
  &SavesPending {
    background-color: var(--primary-color);
    color: white !important;

    &:hover {
      background-color: #e5ddf3;
      color: black !important;
    }
  }
}

.warningContainer {
  margin-left: 50px;
  padding-top: 30px;
}

.warning {
  font-size: 0.9rem;
  color: var(--primary-color);
}

.allDeptsLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.weeklyNotesBtn {
  position: absolute;
  right: -10px;
  top: 0;
}

.toggleButtons {
  text-align: center;
  margin-top: 16px;
}

.formulaInput {
  display: flex;
  flex-direction: column;
}

.above {
  color: rgba(102, 187, 106, 1);
  width: 100%;
  div {
    width: 80px;
    margin: 0 auto;
    border-radius: 4px;
  }
}

.below {
  color: rgba(239, 83, 80, 1);
  width: 100%;
  div {
    width: 80px;
    margin: 0 auto;
    border-radius: 4px;
  }
}

.averageText {
  width: 100%;
  font-weight: 600;
}

.hidden {
  opacity: 0.55;
}

.toggleText {
  font-size: 16px;
  text-transform: none;
}

.referenceItem {
  max-width: 500px;
  display: flex;
  align-items: center;
}
.referenceItemText {
  max-width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
