.icon {
  margin-bottom: 10px;
  margin-left: auto;
  color: #fff;
}

.listCard {
  background-color: #f4f4f4;
}

.label {
  opacity: 0.8;
  &MarginRight {
    @extend .label;
    margin-right: 10px;
  }
  &MarginTop {
    @extend .label;
    margin-top: 30px;
  }
  &Oblique {
    @extend .label;
    font-style: oblique;
  }
  &Organizer {
    @extend .label;
    font-style: oblique;
    margin-right: 10px;
  }
  &EndMeeting {
    @extend .label;
    background-color: #000000;
    color: white;
    overflow-x: hidden;
    &:hover {
      background-color: #ef5350;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  &Paragraph {
    @extend .flex;
    margin-bottom: 16px;
  }
  &SpaceBetween {
    @extend .flex;
    justify-content: space-between;
  }

  &Center {
    @extend .flex;
    justify-content: center;
  }

  &CenterWrap {
    @extend .flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &PaddingRight {
    @extend .flex;
    padding-right: 10px;
  }
}

.parapgraph {
  margin-bottom: 16px;
}

.check {
  margin-left: 5px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  margin-left: 10px;
  &Blue {
    @extend .button;
    background-color: #42a5f5;
    &:hover {
      background-color: #1e88e5;
    }
  }
  &Red {
    @extend .button;
    background-color: #ef5350;
    &:hover {
      background-color: #e53935;
    }
  }
  &Purple {
    @extend .button;
    background-color: #ab47bc;
    &:hover {
      background-color: #8e24aa;
    }
  }
}

.avatar {
  margin-right: 15px;
}

.timer {
  display: inline-block;
  min-width: 40px;
}

.previousMeetings {
  max-height: 265px;
  overflow: hidden;
  overflow-y: auto;
}

.newMeeting {
  text-align: center;
}

.status {
  text-transform: capitalize;
  &Purple {
    @extend .status;
    color: var(--secondary-color);
  }
}

.list {
  @media only screen and (max-width: 960px) {
    display: flex;
    overflow: auto;
    li {
      white-space: nowrap;
    }
  }
  &Container {
    width: 100%;
    margin-top: 50px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.objectiveCard {
  margin: 20px 0;
  &List {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.divider {
  margin-top: 16px;
  &Vertical {
    margin: 0 16px;
  }
}

.issueCard {
  &Container {
    margin: 20px 0;
  }
  &Placeholder {
    @extend .issueCard;
    height: 400px;
  }
  &Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }
  margin: 10px;
  flex: 1;
  max-width: 400px;
  min-width: 300px;
  height: 100%;
  padding-bottom: 10px;
}

.progressWrapper {
  position: relative;
  span {
    font-weight: 600;
    font-size: 11px;
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.warning {
  color: #ffb74d;
}

.delete {
  color: #ef5350;
}

.downloadLink {
  color: var(--primary-color);
  text-decoration: none;
  display: block;
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.image {
  max-width: 100%;
}

.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.rating {
  position: relative;
  margin: 10px auto;
  max-width: 500px;
}

.duration {
  width: 125px;
  margin-left: 10px;
}

.alias {
  width: calc(100% - 150px);
}

.steps {
  margin-top: 20px;
}

.overallRating {
  text-align: center;
  height: 40px;
  font-weight: 600;
  span {
    display: inline-flex;
    align-items: flex-end;
    color: #ffa726;
  }
}

.hoverRating {
  font-weight: 600;
  position: absolute;
  right: -30px;
  top: 13px;
}

.iconAccuracy {
  margin-right: 10px;
}

.value {
  width: 100%;
  input {
    text-align: center;
    padding: 10px 0;
  }
  div {
    width: 75%;
    max-width: 125px;
    margin: 0 auto;
    border-radius: 4px;

    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
  &Above {
    @extend .value;
    input {
      background-color: rgba(102, 187, 106, 0.3);
    }
  }
  &Below {
    @extend .value;
    input {
      background-color: rgba(239, 83, 80, 0.3);
    }
  }

  &Text {
    font-weight: 600;
  }
}

.metricToggle {
  position: relative;
  label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  input {
    color: transparent;
    opacity: 0.5;
    border-radius: 4px;
    &:focus {
      opacity: 1;
    }
  }
}

.menulist {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
  }
}

.stepContainer {
  position: relative;
  width: 100%;
  margin-top: -30px;
}

.meetingTopBar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  display: flex;
  justify-content: space-between;
}

.stepContent {
  margin-top: 66px;
  padding: 20px;
}

.userContainer {
  display: flex;
  align-items: center;
}

.notificationBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.stepItem {
  position: relative;
  overflow: hidden;
}

.stepTimeIndicator {
  position: absolute;
  bottom: 0;
  height: 5px;
  left: 0;
  width: 100%;
  background-color: #1e88e5;
  transition: transform 1s linear;
}
