.label {
  opacity: 0.65;
  &Padded {
    @extend .label;
    margin-left: 16px;
  }
}

.flex {
  display: flex;
  align-items: center;
  &End {
    @extend .flex;
    justify-content: flex-end;
  }
  &Paragraph {
    @extend .flex;
    margin-bottom: 16px;
  }
  &Between {
    @extend .flex;
    justify-content: space-between;
  }
}
