.paper {
  background-color: var(--primary-color);
  width: 100%;
  height: 60px;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  overflow: hidden;
  z-index: 1201;
}

.name {
  color: #fff;
  font-size: 30px;
  margin: 0 5px 0 10px;
  white-space: nowrap;
  padding-right: 10px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.pathname {
  color: #fff;
  font-size: 24px;
  margin: 0 10px 0 10px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.right {
  display: flex;
  margin-left: auto;
  min-width: 100px;
}

.divider {
  background-color: #ffff;
}

.selectOrg {
  margin-right: 10px;
}
