.paper {
  background-color: #263238;
  width: 225px;
  align-items: center;
  padding: 20px 10px;
  padding-top: 70px;
  border-right: none;
  color: #fff;
  overflow-x: hidden;

  // For hiding the scrollbar for the sidebar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &Mobile {
    @extend .paper;
    padding-top: 20px;
  }
}

// For hiding the scrollbar for the sidebar
.paper::-webkit-scrollbar {
  display: none;
}

.userWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.avatarWrapper {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  flex-shrink: 0;
}

.avatar {
  background-color: var(--primary-color);
  height: 100%;
  width: 100%;
  font-size: 12px;
  z-index: 1;
}

.settings {
  position: absolute;
  bottom: -5px;
  right: 0;
  z-index: 2;
}

.userName {
  font-size: 14px;
  font-weight: bold;
  flex-grow: 1;
  max-width: 150px;
  overflow: hidden;
  inline-size: min-content;
}

.user {
  padding: 16px 10px 10px;
  background-color: #37474f;
  color: #fff;
  width: 100%;
}

.authState {
  font-style: oblique;
  text-transform: capitalize;
  opacity: 0.5;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.link {
  text-decoration: none;
  color: inherit;
}

.list {
  width: 100%;
}

.icon {
  color: #fff;
  margin-left: 8px;
}

.nested {
  padding-left: 30px;
  max-width: 210px;
  overflow: hidden;
}

.departmentSelector {
  color: #fff;
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
}

.text {
  // white-space: nowrap;
  // max-width: 120px;
  overflow: visible;
}

.columnFlex {
  display: flex;
  flex-direction: column;
}

.drawer {
  position: absolute;
  right: -50px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 50px;
  height: 50px;
}
