.container {
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.label {
  opacity: 0.65;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.flex {
  display: flex;
  align-items: center;
  &Between {
    @extend .flex;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
    }
  }
  &Inline {
    @extend .flex;
    display: inline-flex;
    font-weight: 700;
    text-transform: capitalize;
  }
  &Center {
    @extend .flex;
    justify-content: center;
    gap: 8px;
  }
}

.red {
  color: #f44336;
}

.wizardDialogContent {
  margin: 0px 24px 8px;
  padding: 0;
  border: 1px solid rgba(224, 224, 224, 1);
}

.wizardTableContainer {
  max-height: 500px;
}

.rockMainRow > * {
  border-bottom: unset;
}

.closeDialogDeptsList {
  padding: 0 0 8px 8px;
  max-height: 465px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  li {
    padding: 8px;
    width: auto;
  }
}

.wizardMessage {
  padding: 8px;
}

.stepper {
  background-color: #e5ddf3;
  position: sticky;
  top: 0;
  z-index: 10;
}

.tooltipList {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    padding: 8px 0;
    width: max-content;
  }
}

.gradeContainer {
  padding: 16px;
  display: flex;
  gap: 8px;

  .gradeTextField {
    flex: 1;
  }
}

.summaryYearTableContainer {
  margin: 4px 0 16px 0;

  table {
    width: 90%;
    margin-left: auto;

    thead {
      background-color: var(--primary-color);
      border: 1px solid white;

      th {
        color: white;
      }
    }

    tbody {
      > tr {
        background-color: #fd9ba2;
      }

      td {
        padding: 6px !important;
        border: 1px solid white;

        &.currentYear > p {
          font-weight: bold;
        }
      }
    }
  }
}
