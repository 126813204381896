.content {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  margin-top: 60px;
  margin-left: 225px;
  background-color: #fff;
  height: calc(100vh - 60px);
  overflow-x: hidden;

  transition: margin-left 195ms;

  &Fullscreen {
    @extend .content;
    margin-left: 0;
  }
}
