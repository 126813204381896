.button {
  min-width: 81px;
}
.metricInput {
  display: flex;
}

.unit {
  max-width: 150px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.modelButtons {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.toggleText {
  font-size: 16px;
  text-transform: none;
}
.field {
  margin-right: 20px;
  flex: 1;
}

.buttonText {
  margin-left: 9px;
  margin-right: auto;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 70px;
}

.referenceText {
  // margin-right: 20px;
  max-height: 70px;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0;
}

.textFields {
  margin-right: 20px;
  flex: 1;
}

.auto {
  margin: 16px auto 0;
}

.resetBtn {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
