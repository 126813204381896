.button {
  min-width: 81px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.resetBtn {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
